import React, {useEffect, useState} from "react"

import {
	Row,
	Col,
} from "reactstrap"
import Field from "../../../../components/Common/CustomForm/Field";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {useOvermind} from "../../../../overmind";
import {dateIsoToBr} from "../../../../renders/utils";
import Button from "../../../../components/Common/Button/Button";

const fundsByName = {
	'adesao_rf' : 'FIDUC RENDA FIXA',
	'adesao_mm' : 'FIDUC MULTIMERCADO',
	'adesao_rv' : 'FIDUC RENDA VARIAVEL',
	'adesao_internacional' :'FIDUC INTERNACIONAL',
	'desenquadramento' :'Termo de desenquadramento',
}

const MismatchTermDocument = ({
	formId,
	...props
}) => {

	const [data, setData] = useState(null)
	const client  = useOvermind('clients')

	const [openingTerm, setOpeningTerm] = useState({})

	const openTerm = (docToken, openKey) => {
		if (!openKey) openKey = docToken
		setOpeningTerm({
			...openingTerm,
			[openKey] : true
		})
		client.actions
			.openZasignDocument(docToken)
			.finally(() => {
				setOpeningTerm({
					...openingTerm,
					[openKey] : false
				})
			})
	}


	useEffect(() => {
		//alert(client.actions.loadZapsignDocuments)
		client.actions.loadZapsignDocuments()
		 	.then(result => {
		 		setData(
		 			result.filter(item => [
		 				'desenquadramento',
					].includes(item.template_name))
				)
		 		console.log('loadZapsignDocuments', result)
		 	})
			.catch((e) => {
				setData(null)
				//alert("Não foi possível")
			})
	}, [])

	return (data && data.length>0) && <>
		<Field
			formId={formId}
			type="title"
			caption="Termo de desenquadramento"
		/>
		<DefaultTable
			className="pt-0 mt-0"
			columns={[
				{id:'template_name', width:'60%', caption:'Nome do fundo', formatter:(row) => fundsByName[row.template_name]},
				{id:'created_at', width:'20%', caption:'Data', formatter:(row) => dateIsoToBr(row.created_at)},
				{id:'actions', width:'20%', caption:'Documento',
					formatter : function Actions(item) {
						return <>
							<Button
								className="btn btn-primary btn-block"
								type="button"
								loading={openingTerm[item.doc_token]}
								onClick={() => openTerm(item.doc_token)}
							>
								Abrir
							</Button>
						</>
					}
				},
			]}
			data={data}
		/>

	</>

}

export default MismatchTermDocument
